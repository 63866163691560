@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500&family=Roboto&display=swap");

:root {
	/* color */
	--purple-50: #7037f0;
	--purple-80: #d6c6fb;
	--red-50: #e41111;
	--black-00: #080217;
	--black-30: #454151;
	--black-40: #817f8b;
	--black-70: #dcdce2;
	--black-90: #fafbff;
	--white: #fff;
	--black: var(--black-00);
	/* ui color */
	--primary-color: var(--purple-50);
	--primary-container-color: var(--purple-80);
	--background-color: var(--black-90);
	--border-color: var(--black-70);
	--on-background-color: var(--black-30);
	--on-background-secondary-color: var(--black-40);
	--error-color: var(--red-50);
	/* font size */
	--fs-normal: 14px;
	--fs-h1: 40px;
	--fs-h2: 32px;
	--fs-h3: 28px;
	--fs-h4: 24px;
	--fs-h5: 20px;
	--fs-h6: 16px;
	--fs-caption: 12px;
	--fs-caption-sm: 10px;
	/* font weight */
	--fw-bold: 500;
	--fw-normal: 400;
	--fw-num-bold: 600;
	/* line-height */
	--lh-normal: calc(var(--fs-normal) + 8px);
	--lh-h1: calc(var(--fs-h1) + 8px);
	--lh-h2: calc(var(--fs-h2) + 8px);
	--lh-h3: calc(var(--fs-h3) + 8px);
	--lh-h4: calc(var(--fs-h4) + 8px);
	--lh-h5: calc(var(--fs-h5) + 8px);
	--lh-h6: calc(var(--fs-h6) + 8px);
	--lh-caption: calc(var(--fs-caption) + 8px);
	--lh-caption-sm: calc(var(--fs-caption-sm) + 8px);
	/* border-radius */
	--br-lg: 16px;
	--br-md: 8px;
	--br-sm: 4px;
	/* box-shadow */
	--bs-layer1: 8px 11px 29px 0px rgba(129, 127, 139, 0.15);
	/* width: 12column */
	--col-1: 110px;
	--col-4: calc(var(--col-1) * 4);
}
html {
	height: 100%;
}
body {
	height: 100%;
	margin: 0;
	font-family: "Noto Sans KR", sans-serif;
	font-weight: var(--fw-normal);
	font-size: var(--fs-normal);
	color: var(--on-background-color);
}
/* layout */
#root {
	height: 100%;
}
.App {
	/* display: flex;
	flex-direction: column; */
	min-height: 100%;
	background: var(--background-color);
}
.page_wrap {
	/* display: flex;
	flex: 1; */
	position: relative;
	padding-left: 300px;
}
.page_wrap .container {
	position: relative;
	width: 100%;
	max-width: 1100px;
	min-height: 100vh;
	padding: 144px 40px 72px 40px;
	margin: 0 auto;
	overflow: auto;
}
/* ------------------------------- common ------------------------------- */
.content_box {
	border-radius: var(--br-md);
	background: var(--white);
	box-shadow: var(--bs-layer1);
}
h1,
.title_h1 {
	line-height: var(--lh-h1);
	font-size: var(--fs-h1);
	font-weight: var(--fw-bold);
}
h2,
.title_h2 {
	line-height: var(--lh-h2);
	font-size: var(--fs-h2);
	font-weight: var(--fw-bold);
}
h3,
.title_h3 {
	line-height: var(--lh-h3);
	font-size: var(--fs-h3);
	font-weight: var(--fw-bold);
}
h4,
.title_h4 {
	line-height: var(--lh-h4);
	font-size: var(--fs-h4);
	font-weight: var(--fw-bold);
}
h5,
.title_h5 {
	line-height: var(--lh-h5);
	font-size: var(--fs-h5);
	font-weight: var(--fw-bold);
}
h6,
.title_h6 {
	line-height: var(--lh-h6);
	font-size: var(--fs-h6);
	font-weight: var(--fw-bold);
}
.col_4 {
	width: var(--col-4);
}
/* button */
button {
	padding: 0;
	border: 0 none;
	background: none;
	box-sizing: border-box;
	font-family: "Noto Sans KR", sans-serif;
	line-height: var(--lh-caption);
	font-weight: var(--fw-bold);
}
button.primary {
	border: 1px solid var(--primary-color);
	background-color: var(--primary-color);
	color: var(--white);
}
button.em {
	border: 1px solid var(--black);
	background-color: var(--black);
	color: var(--white);
}
button.cancel {
	border: 1px solid var(--on-background-secondary-color);
	background-color: var(--on-background-secondary-color);
	color: var(--white);
}
button.another {
	border: 1px solid var(--primary-color);
	background-color: var(--white);
	color: var(--primary-color);
}
button.error {
	border: 1px solid var(--error-color);
	background-color: var(--error-color);
	color: var(--white);
}
button.no_em {
	border: 1px solid var(--on-background-secondary-color);
	background-color: var(--white);
	color: var(--on-background-secondary-color);
}
button.big {
	padding: 18px 24px;
	border-radius: var(--br-md);
	font-size: var(--fs-normal);
}
button.small {
	padding: 6px 8px;
	border-radius: var(--br-md);
	font-size: var(--fs-caption);
}

/* input */
label {
	display: block;
	font-size: var(--fs-normal);
	margin-bottom: 6px;
}
input {
	border: 1px solid var(--border-color);
	border-radius: var(--br-md);
	background-color: var(--white);
	font-size: var(--fs-normal);
	color: var(--on-background-color);
	padding: 0 16px;
	height: 48px;
	box-sizing: border-box;
}
input::placeholder {
	font-size: var(--fs-normal);
	color: var(--on-background-secondary-color);
}
input.error {
	border: 1px solid var(--error-color);
	color: var(--error-color);
}
.error_msg {
	padding: 4px 0;
	font-size: var(--fs-caption-sm);
	color: var(--error-color);
}
.input_wrap .input_area {
	display: flex;
	align-items: center;
	width: 100%;
}
.input_wrap .input_area input {
	flex: 1;
}
.input_wrap .input_area button {
	width: auto;
	margin: 0 0 0 8px;
	padding: 16px 24px;
	line-height: 1;
}
.file_upload {
	position: relative;
	border: 1px solid var(--primary-color);
	background-color: var(--white);
	color: var(--primary-color);
	cursor: pointer;
}
.file_upload > input {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	opacity: 0;
}

/* select (custom) */
.custom_select {
	min-width: 250px;
	position: relative;
}
.custom_select .label {
	margin-bottom: 6px;
}
.custom_select .select_body {
	position: relative;
	width: 100%;
	height: 48px;
	padding: 8px 56px 8px 16px;
	border: 1px solid var(--border-color);
	border-radius: var(--br-md);
	background: var(--white);
	color: var(--on-background-secondary-color);
	line-height: 30px;
}
.custom_select .select_body::after {
	content: "";
	position: absolute;
	top: 11px;
	right: 16px;
	width: 24px;
	height: 24px;
	background-image: url("./assets/icon_arrow_down.svg");
	background-repeat: no-repeat;
	background-position: center;
	transform: rotate(180deg);
}
.custom_select .option_list {
	display: none;
	position: absolute;
	z-index: 100;
	width: 100%;
	border: 1px solid var(--border-color);
	border-top: 0 none;
	border-radius: 0 0 8px 8px;
	background: var(--white);
	overflow: hidden;
}
.custom_select .option_list li {
	padding: 12px 16px;
	line-height: 24px;
	border-top: 1px solid var(--border-color);
	color: var(--on-background-secondary-color);
}
.custom_select .option_list li:first-child {
	border-top: 0 none;
}
.custom_select.active .select_body {
	border-radius: 8px 8px 0 0;
}
.custom_select.active .option_list {
	display: block;
}
/* inline link */
.link {
	display: inline-block;
	padding-bottom: 4px;
	line-height: 100%;
	border-bottom: 1px solid var(--on-background-color);
	color: var(--on-background-color);
}
.link.em {
	border-bottom: 1px solid var(--primary-color);
	color: var(--primary-color);
}
/* login info */
.login_info_wrap {
	display: flex;
	margin-left: auto;
}
.login_info_wrap .login_info_links {
	display: block;
	padding: 10px 24px;
	border-radius: var(--br-md);
	font-size: var(--fs-normal);
	font-weight: var(--fw-normal);
	color: var(--white);
}
.login_info_wrap .login_info_links.active {
	background: var(--white);
	font-weight: var(--fw-bold);
	color: var(--primary-color);
}
.login_info_wrap .login_info_btns {
	display: block;
	width: 36px;
	height: 36px;
	margin-left: 16px;
	overflow: hidden;
	box-sizing: border-box;
	border-radius: 100%;
	text-align: center;
	font-size: 0;
}
.login_info_icon_wrap {
	width: 36px;
	height: 36px;
	padding: 6px 0;
	background-color: var(--primary-container-color);
	position: relative;
	overflow: hidden;
}
.profile_img_default {
	width: 100%;
	height: 100%;
	aspect-ratio: 10 / 10;
	object-fit: cover;
}
.login_info_icon_wrap .profile_img_default {
	position: absolute;
	left: 0;
	top: 0;
}
/* .login_info_icon_wrap.profile > .default {
	width: 24px;
	height: 24px;
	position: static;
} */
.login_info_wrap .login_info_btns_name {
	display: none;
}
/* Loading Indicator */
.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	padding: 10px;
	background: var(--primary-color);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	z-index: 10;
}
.loading-dot {
	float: left;
	width: 8px;
	height: 8px;
	margin: 0 4px;
	background: white;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: loadingFade 1s infinite;
	-moz-animation: loadingFade 1s infinite;
	animation: loadingFade 1s infinite;
}
.loading-dot:nth-child(1) {
	-webkit-animation-delay: 0s;
	-moz-animation-delay: 0s;
	animation-delay: 0s;
}
.loading-dot:nth-child(2) {
	-webkit-animation-delay: 0.1s;
	-moz-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.loading-dot:nth-child(3) {
	-webkit-animation-delay: 0.2s;
	-moz-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.loading-dot:nth-child(4) {
	-webkit-animation-delay: 0.3s;
	-moz-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

@-webkit-keyframes loadingFade {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.8;
	}
	100% {
		opacity: 0;
	}
}

@-moz-keyframes loadingFade {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.8;
	}
	100% {
		opacity: 0;
	}
}

@keyframes loadingFade {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.8;
	}
	100% {
		opacity: 0;
	}
}

/* header */
.header {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 72px;
	padding: 0 16px;
	box-sizing: border-box;
	background: var(--primary-color);
	z-index: 2;
}
.header .logo {
	display: flex;
	align-items: center;
	font-size: var(--fs-h4);
	font-weight: var(--fw-bold);
	color: var(--white);
}
.header .logo .logo_img {
	height: 34px;
}
/* 모바일 전용 버튼 */
.header .button_navi {
	display: none;
	width: 24px;
	height: 24px;
	margin: 0 8px;
}
/* nav */
.nav_drawer_wrap {
	display: none;
}
.nav {
	/* flex: 0 0 auto; */
	position: fixed;
	top: 0;
	left: 0;
	width: 300px;
	height: 100%;
	padding: 88px 0 16px 0;
	background: var(--white);
	box-shadow: var(--bs-layer1);
	display: flex;
	flex-direction: column;
}
.nav_menu {
	flex: 1;
	padding: 0 16px;
	overflow-y: auto;
}
.nav a {
	display: flex;
	align-items: center;
	height: 56px;
	padding: 0 16px;
	border-radius: var(--br-md);
	color: var(--on-background-color);
}
.nav a.active {
	background: var(--primary-container-color);
	color: var(--primary-color);
}
.nav a:hover {
	background: var(--background-color);
	color: var(--primary-color);
}
.nav a:focus {
	background: var(--primary-container-color);
	color: var(--primary-color);
}
.nav .home_link {
	padding: 0 16px;
}
.nav .home_link svg {
	margin-right: 12px;
}
.nav .home_link a:hover svg path,
.nav .home_link a:focus svg path {
	fill: var(--primary-color);
}
.nav a .toggle_updown {
	width: 24px;
	height: 24px;
	margin-left: auto;
	transform: rotate(180deg);
}
.nav a .toggle_updown > img {
	width: 24px;
	height: 24px;
}
.nav a .toggle_updown.unfold {
	transform: rotate(0deg);
}
.depth2 {
	padding: 0;
	transition: all 0.3s;
	max-height: 0;
	overflow: hidden;
}
.depth2 .nav_menu_item a {
	position: relative;
	height: 36px;
	padding: 0 16px 0 36px;
	font-size: var(--fs-caption);
}
.depth2 .nav_menu_item a:hover {
	background: none;
	color: var(--primary-color);
}
.depth2 .nav_menu_item a:hover::before,
.depth2 .nav_menu_item a.active::before {
	content: "";
	position: absolute;
	left: 16px;
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background: var(--primary-color);
}
.nav_menu.depth2.unfold {
	max-height: fit-content;
	padding: 12px 0;
}
.nav_info_wrap {
	margin-top: auto;
	padding: 24px 16px;
	border-top: 1px solid var(--border-color);
}
.nav_info_wrap .statistics {
	display: flex;
	/* margin-bottom: 24px; */
}
.nav_info_wrap .statistics .info {
	padding-left: 16px;
}
.nav_info_wrap .statistics .info.all {
	padding-left: 0;
	flex: 1;
}
.nav_info_wrap .statistics .info_title {
	line-height: var(--lh-caption);
	font-weight: var(--fw-bold);
}
.nav_info_wrap .statistics .info_content {
	line-height: var(--lh-caption);
	font-weight: var(--fw-bold);
	font-size: var(--fs-caption);
	color: var(--on-background-secondary-color);
}
.nav_info_wrap .user_rank .title {
	font-weight: var(--fw-bold);
	margin-bottom: 14px;
}
.nav_info_wrap .user_rank .user_rank_item {
	display: flex;
	align-items: center;
}
.nav_info_wrap .user_rank .user_rank_item .icon {
	width: 24px;
	height: 24px;
	margin-right: 4px;
}
.nav_info_wrap .user_rank .user_rank_item .grade {
	margin-right: 12px;
	line-height: var(--lh-caption);
	font-weight: var(--fw-bold);
}
.nav_info_wrap .user_rank .user_rank_item .user {
	line-height: var(--lh-caption);
	font-size: var(--fs-caption);
	color: var(--on-background-secondary-color);
}
/* pagination */
.pagination {
	display: flex;
	justify-content: center;
	margin: 48px 0;
}
.pagination .arrow {
	width: 24px;
	height: 24px;
	background: url(../src/assets/arrow_pagination.svg) no-repeat 0 0;
	font-size: 0;
	overflow: hidden;
	cursor: pointer;
}
.pagination .arrow.right {
	transform: rotate(180deg);
}
.pagination .page_num {
	display: flex;
	margin: 0 16px;
}
.pagination .page_num .page_button {
	width: 24px;
	height: 24px;
	margin-left: 8px;
	border-radius: var(--br-sm);
	text-align: center;
	line-height: 24px;
	font-family: "Roboto";
	font-weight: var(--fw-num-bold);
	cursor: pointer;
}
.pagination .page_num .page_button:first-child {
	margin-left: 0;
}
.pagination .page_num .page_button:hover {
	background: var(--primary-container-color);
}
.pagination .page_num .page_button.active {
	background: var(--primary-color);
	color: var(--white);
}
/* tab */
.tab_menu {
	display: flex;
	align-items: center;
}
.tab_menu .tab_button {
	position: relative;
	padding: 12px 24px 14px 24px;
	cursor: pointer;
}
.tab_menu .tab_button.active {
	color: var(--primary-color);
}
.tab_menu .tab_button.active::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: var(--primary-color);
}
.tabContentItem {
	display: none;
}
.tabContentItem.show {
	display: block;
}
/* input_box */
.input_box {
	margin: 0 auto;
}
.input_box .title_h3 {
	margin-bottom: 32px;
	text-align: center;
}
.input_box .content {
	width: 100%;
}
.input_box .content .input_wrap {
	width: 100%;
	margin-bottom: 8px;
}
.input_box button {
	width: 100%;
	margin-top: 32px;
}

/* ------------------------------- pages ------------------------------- */
/* home */
.home_main {
	width: 100%;
}

/* login */
.login_box {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
	width: var(--col-4);
}
.sns_login_wrap {
	margin-top: 32px;
}
.sns_login_title {
	margin-bottom: 8px;
	text-align: center;
	font-size: var(--fs-normal);
}
.sns_login_description {
	margin-bottom: 8px;
	text-align: center;
	line-height: var(--lh-caption);
	font-size: var(--fs-caption);
	color: var(--on-background-secondary-color);
}
.sns_login_btns {
	display: flex;
	justify-content: center;
	margin-top: 24px;
}
.sns_login_btns .sns_btn {
	display: block;
	width: 50px;
	height: 48px;
	margin-left: 16px;
	margin-top: 0;
	background-image: url("./assets/sns_login_btns.png");
	background-repeat: no-repeat;
	font-size: 0;
}
.sns_login_btns .sns_btn.kakao {
	background-position: 0 0;
}
.sns_login_btns .sns_btn.naver {
	background-position: -51px 0;
}
.sns_login_btns .sns_btn.google {
	background-position: -103px 0;
}
/* signup */
.sign_box {
	width: var(--col-4);
}
.sign_box .validate_email_wrap {
	margin-bottom: 16px;
}
.sign_box .validate_email_wrap button {
	margin-top: 8px;
}
.validate_email_msg {
	padding: 8px 0;
	margin-top: 8px;
	text-align: center;
	font-size: var(--fs-caption-sm);
}
.validate_email_msg.error {
	color: var(--error-color);
}

/* Board */
/* board common */
.board_box {
	background: var(--white);
	border-radius: var(--br-md);
	box-shadow: var(--bs-layer1);
}
.category_tag {
	width: fit-content;
	padding: 4px 8px;
	border-radius: var(--br-sm);
	background: var(--primary-container-color);
	font-size: var(--fs-caption-sm);
	color: var(--primary-color);
}
.category_tag.no_category {
	background: var(--border-color);
	color: var(--on-background-secondary-color);
}
.board_date {
	font-family: "Roboto";
	font-size: var(--fs-caption);
	color: var(--on-background-secondary-color);
}
.board_writer {
	font-size: var(--fs-caption);
	color: var(--on-background-secondary-color);
}
.info_item {
	display: flex;
	align-items: center;
	margin-left: 8px;
}
.info_item .icon {
	font-size: 0;
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-right: 2px;
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;
}
.info_item .info {
	font-family: "Roboto";
	font-size: var(--fs-caption);
	color: var(--on-background-secondary-color);
}
.info_item.comments_info .icon {
	background-image: url("./assets/icon_sms.svg");
}
.info_item.votes_info {
	margin-left: auto;
	height: 20px;
	padding: 0 8px;
	border-radius: 20px;
	border: 1px solid var(--border-color);
	background-color: var(--white);
}
.info_item.votes_info .icon {
	background-image: url("./assets/icon_thumb_up.svg");
}
.info_item.votes_info.voted {
	background-color: var(--on-background-color);
}
.info_item.votes_info.voted .info {
	color: var(--white);
}
/* board editor */
.board_editor_button_wrap {
	display: flex;
	align-items: center;
	padding: 32px 0;
}
.board_editor_button_wrap > button {
	flex: 1;
}
.board_editor_button_wrap > button:first-child {
	margin-right: 16px;
}
/* board list */
.board_title_wrap {
	padding-bottom: 32px;
}
.board_title_desc {
	margin-top: 8px;
	color: var(--on-background-secondary-color);
}
.no_board_list {
	padding: 48px 0;
}
.board_list_box {
	padding: 0 16px;
	background: var(--white);
	border-radius: var(--br-md);
	box-shadow: var(--bs-layer1);
}
.board_list_box li {
	border-top: 1px solid var(--border-color);
}
.board_list_box li:first-child {
	border-top: 0 none;
}
/* board_item */
.board_item {
	padding: 24px;
}
.board_item > a {
	color: var(--on-background-color);
}
.board_item .board_item_element_wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.board_item .board_info {
	display: flex;
	align-items: center;
	margin-left: auto;
}
.board_item .title {
	margin: 14px 0;
	line-height: 100%;
	font-size: var(--fs-h5);
	font-weight: var(--fw-bold);
}
.board_item .comment {
	margin: 8px 0;
	line-height: 100%;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
/* board detail */
.board_detail_box {
	padding: 48px;
}
.board_detail_box .category_tag {
	margin-bottom: 12px;
}
.board_detail_box .board_date {
	margin-bottom: 12px;
}
.board_detail_box .board_detail_title {
	padding-bottom: 16px;
	border-bottom: 1px solid var(--border-color);
}
.board_detail_info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0;
	margin-bottom: 12px;
}
.board_detail_info .board_info {
	color: var(--on-background-color);
}
.board_detail_info .writer_profile {
	display: flex;
	align-items: center;
}
.board_detail_info .writer_profile .profile_img_wrap {
	background: var(--border-color);
	width: 20px;
	height: 20px;
	border-radius: 20px;
	overflow: hidden;
	margin-right: 4px;
}
/* .board_detail_info .info_item {
	position: relative;
}
.board_detail_info .info_item .info {
	padding: 2px 8px 2px 26px;
	margin-bottom: 0;
	border-radius: 20px;
	border: 1px solid var(--border-color);
}
.board_detail_info .info_item .icon {
	appearance: none;
	position: absolute;
	border: 0 none;
	padding: 0;
	left: 8px;
	top: 2px;
	margin: 0;
	background-color: transparent;
}
.board_detail_info .info_item .icon:checked + label {
	background: var(--on-background-color);
	color: var(--white);
} */
.board_detail_box .writer_action {
	text-align: right;
}
.board_detail_box .writer_action .link {
	margin-left: 16px;
}
/* 게시글 상세보기 스타일: 시작 */
.board_content {
	padding: 48px 0;
}
.board_content img {
	max-width: 100%;
}
.board_content p {
	margin: 8px 0;
	line-height: var(--lh-normal);
	font-size: var(--fs-normal);
}
.board_content em {
	font-style: italic;
}
.board_content strong {
	font-weight: var(--fw-bold);
}
.board_content blockquote {
	padding-left: 16px;
	border-left: 4px solid var(--border-color);
	margin-left: 20px;
}
.board_content hr {
	height: 1px;
	margin: 24px 0;
	border: 0 none;
	background: var(--border-color);
}
.board_content a {
	text-decoration: underline;
	color: var(--primary-color);
}
.board_content ul li {
	margin-left: 20px;
	list-style: disc;
}
.board_content ol li {
	margin-left: 20px;
	list-style: decimal;
}
.board_content .task-list-item {
	margin-left: 0;
	list-style: none;
}
.board_content .task-list-item ::before {
	content: "";
	background: url("./assets/icon_unchecked_box.svg") no-repeat;
	display: inline-block;
	width: 24px;
	height: 24px;
	margin-right: 4px;
	vertical-align: top;
}
.board_content .task-list-item.checked ::before {
	background: url("./assets/icon_checked_box.svg") no-repeat;
}
/* 게시글 상세보기 - 댓글 */
/* 댓글 작성 */
.comment_write {
	padding: 32px 24px;
	border: 1px solid var(--border-color);
	border-radius: var(--br-md);
}
.comment_write_title {
	line-height: var(--fs-h6);
	font-size: var(--fs-h6);
	font-weight: var(--fw-bold);
}
.comment_write_body {
	margin: 16px 0;
}
.comment_write textarea {
	width: 100%;
	min-height: 112px;
	padding: 12px 16px;
	border: 1px solid var(--border-color);
	border-radius: var(--br-md);
	background: var(--background-color);
	resize: none;
}
.comment_write button {
	display: block;
	width: 160px;
	margin-left: auto;
}
/* 댓글 목록 */
.comments_list_wrap {
	padding: 32px 0;
	margin-top: 48px;
	border-top: 1px solid var(--border-color);
}
.comments_title {
	margin-bottom: 32px;
	line-height: var(--fs-h6);
	font-size: var(--fs-h6);
	font-weight: var(--fw-bold);
}
.comment_list_item {
	/* padding: 16px 0 0 0; */
	border-bottom: 1px solid var(--border-color);
}
.comment_removed {
	padding: 24px 0;
}
.comment_head {
	display: flex;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 12px;
}
.comment_user_profile {
	display: flex;
	align-items: center;
}
.comment_user_profile .user_img_wrap {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	background: var(--primary-container-color);
	overflow: hidden;
	margin-right: 8px;
}
.comment_user_profile .user_name {
	font-size: var(--fs-normal);
	font-weight: var(--fw-normal);
	color: var(--black);
	margin-right: 8px;
}
.comment_user_profile .comment_ceated_time {
	color: var(--on-background-secondary-color);
}
/* .comment_likes {
	margin-left: auto;
	height: 20px;
	padding: 0 8px 0 26px;
	border-radius: 20px;
	border: 1px solid var(--border-color);
	background: url("./assets/icon_thumb_up.svg") no-repeat;
	background-position: 8px center;
} */
.comment_content {
	margin-bottom: 12px;
}
.comment_content_textarea {
	width: 100%;
	padding: 12px 16px;
	margin-bottom: 12px;
	border: 1px solid var(--border-color);
	background-color: var(--background-color);
	border-radius: var(--br-sm);
	resize: none;
}
.comment_update_btn_wrap {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.comment_btn {
	padding: 6px 8px;
	margin-right: 8px;
	border-radius: var(--br-md);
	border: 1px solid var(--primary-color);
	background-color: var(--white);
	color: var(--primary-color);
	font-size: var(--fs-caption);
	line-height: 1;
}
.comment_reply_btns_wrap {
	padding-bottom: 16px;
}
.comment_reply_btn {
	margin-right: 16px;
	font-size: var(--fs-caption);
	font-weight: var(--fs-caption);
	color: var(--on-background-color);
}
.comment_reply_btn.create_comment_child:hover {
	color: var(--primary-color);
}
.comment_reply_btn.toggle_view_child_comments {
	color: var(--primary-color);
}
.comment_children_wrap {
	padding: 0 36px 16px 36px;
	background-color: var(--background-color);
	border-top: 1px solid var(--border-color);
}
.comment_children_btn_wrap {
	padding: 24px 0;
}
.comment_children_btn_wrap button.another {
	padding: 0;
	width: 100%;
	height: 48px;
	border: 1px solid var(--on-background-color);
}
.reply_create_wrap {
	width: 100%;
}
.reply_create_wrap textarea {
	width: 100%;
	padding: 8px;
	background: var(--white);
	border: 1px solid var(--border-color);
	border-radius: var(--br-md);
	color: var(--on-background-color);
	resize: none;
}
.reply_create_btns {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 12px;
}
.reply_create_btn {
	margin-left: 8px;
	padding: 6px 8px;
	border: 1px solid var(--primary-color);
	border-radius: var(--br-md);
	background: var(--white);
	color: var(--primary-color);
	line-height: 1;
	font-size: var(--fs-caption);
}

/* board write */
.board_write_box {
	padding: 48px;
}
.board_select_wrap {
	display: flex;
	align-items: center;
}
.board_select_wrap .select_wrap {
	margin-right: 12px;
}
.board_input_title {
	padding: 28px 0 16px 0;
	margin-bottom: 48px;
	border-bottom: 1px solid var(--border-color);
}
.board_input_title input {
	width: 100%;
	font-size: var(--fs-h1);
	border: 0 none;
}
.board_input_title input::placeholder {
	font-family: "Noto Sans KR", sans-serif;
	font-size: var(--fs-h1);
	color: var(--border-color);
}
/* user page */
.user_profile {
	display: flex;
	align-items: center;
	padding: 24px;
	margin-bottom: 32px;
	background: var(--white);
	border-radius: var(--br-md);
	box-shadow: var(--bs-layer1);
}
.profile_img_wrap {
	width: 120px;
	height: 120px;
	border-radius: 100%;
	margin-right: 16px;
	background: var(--primary-container-color);
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
.user_info_wrap {
	margin-right: 16px;
}
.user_info_wrap .email {
	margin-top: 4px;
	color: var(--on-background-secondary-color);
}
.user_button_wrap {
	margin-left: auto;
}
.user_button_wrap .user_button {
	padding: 10px 24px;
	font-size: var(--fs-normal);
	font-weight: var(--fw-bold);
	background: var(--white);
	border-radius: var(--br-md);
	border: 1px solid var(--primary-color);
	color: var(--primary-color);
}
.user_button_wrap > .user_button:first-child {
	margin-right: 12px;
}
.user_written_wrap {
	padding: 16px 0;
	background: var(--white);
	border-radius: var(--br-md);
	box-shadow: var(--bs-layer1);
}
.user_written_wrap .tab_menu {
	border-bottom: 1px solid var(--border-color);
}
.tab_content ul .board_item {
	border-bottom: 1px solid var(--border-color);
}
.tab_content .no_board_list {
	padding: 48px 24px;
}
/* user edit */
.user_edit_wrap {
	padding: 48px;
	background: var(--white);
	border-radius: var(--br-md);
	box-shadow: var(--bs-layer1);
}
.user_edit_wrap .user_profile {
	padding: 0;
	margin-bottom: 24px;
	background: none;
	box-shadow: none;
}
.user_edit_wrap .user_profile .profile_img_wrap {
	width: 56px;
	height: 56px;
}
.user_input_content_wrap .input_wrap {
	margin-bottom: 16px;
}
.user_edit_link_wrap {
	margin: 24px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.user_edit_link_wrap .link {
	margin: 16px 0;
}
.user_edit_link_wrap .link.member_delete {
	color: var(--error-color);
	border-bottom: 1px solid var(--error-color);
}
.user_edit_button_wrap {
	display: flex;
	align-items: center;
}
.user_edit_button_wrap button {
	flex: 1;
}
.user_edit_button_wrap button:first-child {
	margin-right: 16px;
}
/* admin */
.admin_wrap {
	padding-bottom: 120px;
}
.admin_title {
	margin-bottom: 32px;
}
.admin_button_wrap {
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	background: rgb(255 255 255 / 70%);
	width: 100%;
	padding: 24px;
	box-shadow: var(--bs-layer1);
	justify-content: flex-end;
	align-items: center;
	backdrop-filter: blur(10px);
	border-top: 1px solid var(--border-color);
}
.admin_button_wrap button {
	width: 240px;
	margin-left: 16px;
}
.admin_button_wrap button:nth-child(1) {
	margin-left: 0;
}
/* admin board create */
.admin_board_create_wrap {
	padding: 16px;
	margin-bottom: 32px;
	background: var(--white);
	box-shadow: var(--bs-layer1);
	border-radius: var(--br-md);
}
.admin_board_create_wrap .title {
	padding: 0 16px 8px 16px;
	font-size: var(--fs-h6);
	font-weight: var(--fw-bold);
	color: var(--primary-color);
}
.admin_board_create_wrap .input_area input {
	height: 40px;
}
.admin_board_create_wrap .input_area .add_board_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	padding: 0;
}
.admin_board_create_wrap .isAdminOnly_check_wrap {
	display: flex;
	align-items: center;
}
.admin_board_create_wrap .isAdminOnly_check_wrap > input {
	appearance: none;
	-webkit-appearance: none;
	padding: 0;
	width: 24px;
	height: 24px;
	background: none;
	border: 0 none;
}
.admin_board_create_wrap .isAdminOnly_check_wrap > input::before {
	content: "";
	background: url("./assets/icon_unchecked_box.svg") no-repeat;
	display: inline-block;
	width: 24px;
	height: 24px;
	margin-right: 8px;
	vertical-align: top;
}
.admin_board_create_wrap .isAdminOnly_check_wrap > input:checked::before {
	background: url("./assets/icon_checked_box.svg") no-repeat;
}
.admin_board_create_wrap .isAdminOnly_check_wrap > label {
	margin: 0;
	color: var(--on-background-secondary-color);
}
.admin_board_create_wrap .isAdminOnly_check_wrap > input:checked + label {
	color: var(--on-background-color);
}
.admin_board_list_item .admin_board_create_wrap {
	margin-bottom: 0;
	background: var(--background-color);
	border-radius: 0;
	box-shadow: none;
}
/* admin board */
.admin_board_list_wrap {
	padding: 32px;
	margin-bottom: 32px;
	border-radius: var(--br-md);
	background: var(--white);
	box-shadow: var(--bs-layer1);
}
.admin_board_desc {
	margin-bottom: 32px;
	line-height: var(--lh-normal);
}
.admin_board_list_item {
	/* max-height: 52px; */
	margin-top: 24px;
	border-radius: var(--br-md);
	border: 1px solid var(--primary-color);
	overflow: hidden;
}
.admin_board_list_item:nth-child(1) {
	margin-top: 0;
}
.admin_board_list_item .list_button_wrap {
	display: none;
	padding: 0 32px;
}
.admin_board_list_item .list_button_wrap.editing {
	display: block;
}
.admin_board_list_item .list_button_wrap button {
	padding: 4px 8px;
	margin-left: 8px;
}
.admin_board_list_item .list_button_wrap button:nth-child(1) {
	margin-left: 0;
}
.admin_board_list_item.deleted {
	display: none;
}
/* .admin_board_list_head */
.admin_board_list_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 52px;
	padding: 0 32px;
	background: var(--primary-color);
	line-height: var(--lh-normal);
	font-weight: var(--fw-bold);
	color: var(--white);
	cursor: all-scroll;
}
.admin_board_list_head .list_name {
	flex: 1;
}
.admin_board_list_head .admin_board_icon {
	width: 24px;
	height: 24px;
	font-size: 0;
	overflow: hidden;
	transition: all 0.4s;
}
.admin_board_list_head .admin_board_icon svg path {
	fill: var(--white);
}
.admin_board_list_item .admin_board_list_head .list_button_wrap button {
	border: 1px solid var(--white);
	background: none;
	color: var(--white);
}
/* .admin_board_list_head hover */
.admin_board_list_head:hover .list_button_wrap {
	display: block;
}
/* .admin_category_list  */
.admin_category_list {
	background-color: var(--background-color);
	transition: background-color 0.2s ease;
	min-height: 48px;
	overflow: hidden;
}
.category_list_item {
	display: flex;
	align-items: center;
	background: var(--white);
	border-bottom: 1px solid var(--border-color);
}
.category_list_item.deleted {
	display: none;
}
.category_list_item .draggable_icon {
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.category_list_item .draggable_icon > img {
	width: 24px;
	height: 24px;
}
.category_list_item .list_name {
	flex: 1;
	padding: 0 16px;
}
.admin_add_board {
	height: 48px;
	width: 100%;
	text-align: left;
	padding: 0 16px;
	background: var(--primary-container-color);
	color: var(--primary-color);
}
/* category_list hover */
.category_list_item:hover {
	border: 1px solid var(--primary-color);
}
.admin_board_list_item .category_list_item:hover .list_button_wrap {
	display: block;
}
/* admin board dragging */
.admin_category_list.draggingOver {
	background-color: var(--primary-container-color);
}
.category_list_item.dragging {
	background: rgba(255, 255, 255, 0.4);
	border: 1px solid var(--border-color);
}
/* admin board fold */
.admin_board_list_item.fold .admin_category_list {
	min-height: 0;
	max-height: 0;
}
.admin_board_list_item.fold .admin_board_list_head .admin_board_icon {
	transform: rotate(180deg);
}
.admin_board_list_item.fold .admin_board_create_wrap {
	display: none;
}

/* ------------------------------- responsive: mobile ------------------------------- */
@media screen and (max-width: 1023px) {
	:root {
		/* font size */
		--fs-h1: 36px;
		--fs-h2: 32px;
		--fs-h3: 28px;
		--fs-h4: 24px;
		--fs-h5: 22px;
		--fs-h6: 20px;
		--fs-caption: 12px;
		--fs-caption-sm: 10px;
	}
	/* common */
	.page_wrap {
		padding-left: 0;
	}
	.page_wrap .container {
		width: 90%;
		padding: 102px 0 72px 0;
	}
	/* header */
	.header {
		justify-content: space-between;
	}
	.header .logo {
		margin-right: auto;
		font-size: var(--fs-h6);
	}
	.header .button_navi {
		display: block;
	}
	.header .login_info_wrap {
		display: none;
	}
	/* nav, nav_drawer */
	.nav {
		display: none;
	}
	.nav_drawer_wrap {
		display: block;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		/* background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.88) 0%,
			rgba(0, 0, 0, 0.35) 100%
		); */
		opacity: 1;
		z-index: 500;
		overflow: hidden;
		transition: all 0.55s;
		opacity: 1;
		pointer-events: auto;
	}
	.drawer_dim {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.88) 0%,
			rgba(0, 0, 0, 0.35) 100%
		);
		transition: all 0.45s;
		opacity: 1;
	}
	.drawer {
		position: relative;
		width: 300px;
		height: 100%;
		margin-left: auto;
		display: flex;
		flex-direction: column;
		background: var(--white);
		transition: transform 0.45s ease-in-out;
	}
	.drawer_head {
		margin-bottom: 24px;
		background: var(--primary-container-color);
	}
	.nav_drawer_close {
		display: block;
		width: 56px;
		height: 56px;
		font-size: 0;
		overflow: hidden;
	}
	.drawer_title_wrap {
		padding: 8px 16px 24px 16px;
	}
	.drawer_title {
		font-size: var(--fs-h6);
		color: var(--on-background-color);
	}
	.drawer_sub_title {
		color: var(--on-background-secondary-color);
	}
	.drawer .login_info_wrap {
		display: flex;
		align-items: center;
		justify-content: space-around;
		background: var(--primary-color);
		min-height: 56px;
	}
	.drawer .login_info_wrap .login_info_btns {
		width: 100%;
		height: 100%;
		padding: 14px 0;
		margin: 0;
		line-height: 0;
	}
	.drawer .login_info_icon_wrap {
		border-radius: 100%;
		margin: 0 auto;
		background-color: var(--white);
	}
	.drawer .login_info_wrap .login_info_btns_name {
		display: block;
		margin-top: 4px;
		line-height: var(--lh-caption);
		font-size: var(--fs-caption);
		font-weight: var(--fw-normal);
		color: var(--white);
	}
	.drawer .nav {
		display: flex;
		position: static;
		width: 100%;
		padding: 0;
		box-shadow: none;
		flex: 1;
		overflow: auto;
	}
	.nav_drawer_wrap.off {
		/* transition: transform 0.55s;
		transform: translateX(100%); */
		pointer-events: none;
		opacity: 0;
	}
	.nav_drawer_wrap.off .drawer_dim {
		opacity: 0;
	}
	.nav_drawer_wrap.off .drawer {
		transform: translateX(300px);
	}
	/* login */
	.login_box {
		width: 100%;
	}
	.sns_login_description {
		word-break: keep-all;
	}
	/* sign up */
	.sign_box {
		width: 100%;
	}
	/* boardList */
	.board_item .title {
		font-size: var(--fs-h6);
	}
	/* boardDetail */
	.board_detail_box {
		padding: 20px;
	}
	.board_detail_box .board_detail_title {
		line-height: var(--lh-h6);
		font-size: var(--fs-h6);
	}
	.comment_write {
		padding: 16px;
	}
	/* board write */
	.board_input_title input {
		font-size: var(--fs-h6);
	}
	.board_input_title input::placeholder {
		font-size: var(--fs-h6);
	}
	.board_write_box {
		padding: 16px;
	}
	.board_select_wrap {
		display: block;
	}
	.board_select_wrap .select_wrap {
		margin-right: 0;
		margin-bottom: 16px;
	}
	.board_write_box .toastui-editor-dropdown-toolbar {
		height: auto;
		flex-wrap: wrap;
	}
	.board_write_box .toastui-editor-contents {
		font-size: 16px;
	}
	.board_write_box .toastui-editor-popup {
		margin-left: 0;
	}
	.board_editor_button_wrap {
		flex-direction: column;
		align-items: stretch;
	}
	.board_editor_button_wrap > button:first-child {
		margin-right: 0;
		margin-bottom: 16px;
	}
	/* userPage: css는 수정할 것 없음. 컴포넌트 이미지 경로 부분 수정 필요 */
	.user_profile {
		flex-direction: column;
	}
	.profile_img_wrap {
		margin-right: 0;
		width: 100px;
		height: 100px;
	}
	.user_info_wrap {
		margin-right: 0;
		padding-top: 16px;
		text-align: center;
	}
	.user_button_wrap {
		margin: 0 auto;
		padding-top: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.user_button_wrap .user_button {
		display: block;
		text-align: center;
		margin-bottom: 8px;
	}
	.user_button_wrap .user_button:first-child {
		margin-right: 0;
	}
	/* userEdit */
	.user_edit_wrap {
		padding: 24px;
	}
	.user_edit_wrap .user_profile {
		margin-bottom: 40px;
	}
	.user_input_content_wrap .input_wrap {
		margin-bottom: 24px;
	}
	.user_input_content_wrap .input_wrap .input_area {
		flex-direction: column;
		align-items: stretch;
	}
	.user_input_content_wrap .input_wrap .input_area input {
		flex: none;
		margin-bottom: 12px;
	}
	.user_input_content_wrap .input_wrap .input_area button {
		margin: 0;
	}
	.user_edit_button_wrap {
		flex-direction: column;
		align-items: stretch;
	}
	.user_edit_button_wrap button:first-child {
		margin-right: 0;
		margin-bottom: 16px;
	}
	/* admin: 로딩중 기본 스타일 수정할 것 */
	.admin_board_desc {
		word-break: keep-all;
	}
}
